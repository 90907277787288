
import { defineComponent } from 'vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import useStringFormatter from '@/composable/useStringFormatter';

export default defineComponent({
    name: 'advanced-filters',
    components: {
        BButton,
    },
    props: {
        filterDefault: Boolean,
        filterDirty: Boolean,
    },
    emits: ['submit', 'reset'],
    setup(props, context) {
        const { titleCase } = useStringFormatter();

        function resetAllFilters() {
            context.emit('reset');
        }

        function submit() {
            context.emit('submit');
        }

        return {
            resetAllFilters,
            submit,
            titleCase,
        };
    },
});
