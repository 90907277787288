import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_filter_criteria = _resolveComponent("filter-criteria")!
  const _component_advanced_filters = _resolveComponent("advanced-filters")!

  return (_openBlock(), _createBlock(_component_advanced_filters, {
    "filter-default": _ctx.filterDefault,
    "filter-dirty": _ctx.filterDirty,
    onReset: _ctx.resetAllFilters,
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.status')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_form_multi_select, {
            modelValue: _ctx.modelValue.statuses,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.statuses) = $event)),
            "search-by": "name",
            data: _ctx.allStatuses
          }, null, 8, ["modelValue", "data"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["filter-default", "filter-dirty", "onReset", "onSubmit"]))
}