
import { defineComponent } from 'vue';
import Popover from '@/components/bootstrap-library/Popover.vue';

export default defineComponent({
    name: 'warning-icon',
    components: { Popover },
    props: {
        message: { type: [String], default: undefined },
    },
});
