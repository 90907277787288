import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "record-display-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!
  const _component_popover = _resolveComponent("popover")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.message?.length > 0)
      ? (_openBlock(), _createBlock(_component_popover, { key: 0 }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("span", null, _toDisplayString(_ctx.message), 1)
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", null, [
              _createVNode(_component_faicon, {
                size: "2x",
                icon: "exclamation-triangle",
                class: "danger"
              })
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}