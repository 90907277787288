
import { defineComponent, PropType } from 'vue';
import { Formatter, getAllStatuses } from '@/domain/TransactionStatus';
import FormMultiSelect from '@/components/FormMultiSelect.vue';
import FilteredTransactionSearch from '@/dtos/filters/FilteredTransactionSearch';
import AdvancedFilters from '@/components/filters/AdvancedFilters.vue';
import FilterCriteria from '@/components/filters/FilterCriteria.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'partner-engagement-advanced-filters',
    components: {
        AdvancedFilters,
        FilterCriteria,
        FormMultiSelect,
    },
    props: {
        modelValue: {
            type: Object as PropType<FilteredTransactionSearch>,
            default: () => new FilteredTransactionSearch(),
        },
        disabled: Boolean,
        filterDirty: Boolean,
        filterDefault: Boolean,
    },
    emits: ['update:modelValue', 'submit', 'reset'],

    setup(props, context) {
        const allStatuses = getAllStatuses().map((x) => ({ name: Formatter.GetFriendlyValue(x), value: x }));

        async function resetAllFilters() {
            context.emit('reset');
        }

        async function submit() {
            context.emit('submit');
        }

        return {
            allStatuses,
            submit,
            getTitleCaseTranslation,
            resetAllFilters,
        };
    },
});
