import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popover = _resolveComponent("popover")!

  return (_openBlock(), _createBlock(_component_popover, { "use-mouse-position": "" }, {
    content: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.formatDateTimeUI(_ctx.date)), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_ctx.showTime ? _ctx.formatDateTimeUI(_ctx.date) : _ctx.formatDateUI(_ctx.date)), 1)
    ]),
    _: 1
  }))
}