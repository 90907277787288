
import {
    computed, defineComponent, h, onBeforeMount,
} from 'vue';
import Screen from '@/components/layout/Screen.vue';
import { ColumnDefinition, TableDefinition } from '@/types';
import Transaction from '@/domain/Transaction';
import PartnerEngagementRouteTypes from '@/modules/partner-engagement/routes/types';
import TransactionService from '@/services/TransactionService';
import coreStore from '@/store/CoreStore';
import router from '@/router';
import useStringFormatter from '@/composable/useStringFormatter';
import Permissions from '@/services/permissions/Permissions';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import { Formatter, getAllStatuses, TransactionStatus } from '@/domain/TransactionStatus';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import { useNotification } from '@/composable/useNotifications';
import FilteredTransactionSearch from '@/dtos/filters/FilteredTransactionSearch';
import PartnerEngagementAdvancedFilters from '@/components/filters/PartnerEngagementAdvancedFilters.vue';
import SimpleDatePopover from '@/components/SimpleDatePopover.vue';
import { useFilterSearch } from '@/composable/useFilterSearch';
import TransactionDigest from '@/domain/TransactionDigest';
import WarningIcon from '@/components/WarningIcon.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import { isBillOfLadingDisabled } from '@/functions/PrintTransaction';
import NonWorkDayService from '@/services/NonWorkDayService';

type TransactionTableData = {
    id: number;
    direction: string | null;
    transactionNumber: number;
    location: string;
    placementDate: Date;
    status: TransactionStatus;
    shipDate: Date | null;
    deliveryDate: Date | null;
    carrier: string;
    carrierReferenceNumber: string;
    trailer: string;
    partnerReferenceNumber: string;
    transactionDigest: TransactionDigest;
    transactionStatusDescription: string;
    licensePlateNumber: string;
    arrivedAt?: Date;
};

const { confirm } = useDialogBox();

export default defineComponent({
    name: 'partner-engagement-transaction-list',
    components: {
        Screen,
        BAdvancedTable,
        SimpleDatePopover,
    },
    setup() {
        const transactionService = new TransactionService();
        const { profileStore, timeSetStore } = coreStore.getInstance();
        const { capitalizeFirstLetter } = useStringFormatter();
        const notification = useNotification();
        const nonWorkDayService = new NonWorkDayService(timeSetStore.allTimeSets);

        const {
            filterState, submit: executeSearch, reset: resetFilter, isFilterDirty, state, isFilterDefault, recordCountWarning,
        } = useFilterSearch<TransactionDigest, FilteredTransactionSearch>({
            tableKey: 'pemTransactionList',
            searchFunction: transactionService.getFilteredTransactions.bind(transactionService),
            defaultFilters: new FilteredTransactionSearch({
                locations: [profileStore.userLocation],
                statuses: [
                    ...getAllStatuses()
                        .filter((status) => status !== TransactionStatus.PLANNED && status !== TransactionStatus.CONFIRMED && status !== TransactionStatus.DELETED)
                        .map((x) => ({
                            name: Formatter.GetFriendlyValue(x),
                            value: x,
                        })),
                ],
            }),
        });

        onBeforeMount(() => {
            if (filterState.value.locations.length !== 1 || filterState.value.locations[0].id !== profileStore.userLocation.id) {
                // make sure the results are always filtered to the user's current location
                // otherwise the remembered table settings could point to the wrong location after the user changes location
                filterState.value.locations = [profileStore.userLocation];
            }
            executeSearch();
        });

        function getLocationForTable(data: TransactionDigest): string {
            if (data.getDirection(profileStore.userLocation) === 'outbound' && data.toLocationId) {
                return data.toLocationName;
            }
            if (data.getDirection(profileStore.userLocation) === 'inbound' && data.fromLocationId) {
                return data.fromLocationName;
            }
            return '-';
        }

        function mapDigest(data: TransactionDigest): TransactionTableData {
            let shipmentDeliveryDate = data.plannedDeliveryDate ? nonWorkDayService.getNextWorkingDay(data.plannedDeliveryDate, profileStore.userLocation.nonWorkingTimeSetId) : data.dateRequired;

            if (data.arrivedAt) {
                shipmentDeliveryDate = data.arrivedAt;
            }

            return {
                id: data.id,
                direction: capitalizeFirstLetter(data.getDirection(profileStore.userLocation) as string),
                transactionNumber: data.id,
                location: getLocationForTable(data),
                placementDate: data.createdOn,
                transactionStatusDescription: data.transactionStatusDescription,
                status: data.status,
                shipDate: data.shipDate,
                deliveryDate: shipmentDeliveryDate,
                carrier: data.carrier ?? '',
                carrierReferenceNumber: data.carrierReferenceNumber ?? '',
                trailer: data.trailer ?? '',
                partnerReferenceNumber: data.partnerReferenceNumber,
                transactionDigest: data,
                licensePlateNumber: data.licensePlateNumber,
                arrivedAt: data.arrivedAt,
            };
        }

        const allTransactionTableData = computed(() => state.resultSet.filter((data) => data.getDirection(profileStore.userLocation)).map((data) => mapDigest(data)));

        const inboundTransactionTableData = computed(() => state.resultSet.filter((data) => data.getDirection(profileStore.userLocation) === 'inbound').map((data) => mapDigest(data)));

        const outboundTransactionTableData = computed(() => state.resultSet.filter((data) => data.getDirection(profileStore.userLocation) === 'outbound').map((data) => mapDigest(data)));

        const columnDefinition: Array<ColumnDefinition<TransactionTableData>> = [
            {
                key: 'direction',
                label: getTitleCaseTranslation('core.domain.direction'),
            },
            {
                key: 'transactionNumber',
                searchable: true,
                label: getTitleCaseTranslation('core.domain.transactionNumber'),
            },
            {
                key: 'location',
                searchable: true,
                label: getTitleCaseTranslation('core.domain.location'),
            },
            {
                key: 'placementDate',
                label: getTitleCaseTranslation('core.domain.placementDate'),
            },
            {
                key: 'transactionStatusDescription',
                searchable: true,
                label: getTitleCaseTranslation('core.domain.status'),
            },
            {
                key: 'shipDate',
                label: getTitleCaseTranslation('core.domain.shipDate'),
            },
            {
                key: 'deliveryDate',
                label: getTitleCaseTranslation('core.domain.deliveryDate'),
            },
            {
                key: 'carrier',
                searchable: true,
                label: getTitleCaseTranslation('core.domain.carrier'),
            },
            {
                key: 'carrierReferenceNumber',
                searchable: true,
                label: getTitleCaseTranslation('core.domain.carrierReferenceNumber'),
            },
            {
                key: 'trailer',
                searchable: true,
                label: getTitleCaseTranslation('core.domain.trailer'),
            },
            {
                key: 'partnerReferenceNumber',
                searchable: true,
                label: getTitleCaseTranslation('core.domain.partnerReferenceNumber'),
            },
            {
                key: 'licensePlateNumber',
                searchable: true,
                label: getTitleCaseTranslation('core.domain.licenseNumberShort'),
            },
            {
                key: 'arrivedAt',
                searchable: true,
                label: getTitleCaseTranslation('core.domain.receiptDate'),
                hidden: true,
            },
        ];

        const allTransactionsTable = computed(
            (): TableDefinition<TransactionTableData> => ({
                items: allTransactionTableData.value,
                key: 'AllTransactionList',
                name: getTitleCaseTranslation('core.selectOptions.allTransactions'),
                columnDefinition,
                headIcon: () => h(WarningIcon, {
                    message: recordCountWarning.value,
                }),
                sidePane: () => h(PartnerEngagementAdvancedFilters, {
                    onSubmit: executeSearch,
                    onReset: resetFilter,
                    modelValue: filterState.value,
                    disabled: state.loading,
                    filterDirty: isFilterDirty.value,
                    filterDefault: isFilterDefault.value,
                }),
            }),
        );

        const inboundTransactionsTable = computed(
            (): TableDefinition<TransactionTableData> => ({
                items: inboundTransactionTableData.value,
                key: 'InboundTransactionList',
                name: getTitleCaseTranslation('core.selectOptions.inboundTransactions'),
                columnDefinition,
                headIcon: () => h(WarningIcon, {
                    message: recordCountWarning.value,
                }),
                sidePane: () => h(PartnerEngagementAdvancedFilters, {
                    onSubmit: executeSearch,
                    onReset: resetFilter,
                    modelValue: filterState.value,
                    disabled: state.loading,
                    filterDirty: isFilterDirty.value,
                    filterDefault: isFilterDefault.value,
                }),
            }),
        );

        const outboundTransactionsTable = computed(
            (): TableDefinition<TransactionTableData> => ({
                items: outboundTransactionTableData.value,
                key: 'OutboundTransactionList',
                name: getTitleCaseTranslation('core.selectOptions.outboundTransactions'),
                columnDefinition,
                headIcon: () => h(WarningIcon, {
                    message: recordCountWarning.value,
                }),
                sidePane: () => h(PartnerEngagementAdvancedFilters, {
                    onSubmit: executeSearch,
                    onReset: resetFilter,
                    modelValue: filterState.value,
                    disabled: state.loading,
                    filterDirty: isFilterDirty.value,
                    filterDefault: isFilterDefault.value,
                }),
            }),
        );

        function openAddShipment() {
            router.push({ name: PartnerEngagementRouteTypes.TRANSACTION.ADD_SUPPLIER_SHIPPING });
        }

        function openAddOrder() {
            router.push({
                name: PartnerEngagementRouteTypes.TRANSACTION.ADD_SUPPLIER_ORDER,
                params: { transactionType: 'inbound' },
            });
        }

        function openTransaction(transaction: Transaction) {
            router.push({
                name: PartnerEngagementRouteTypes.TRANSACTION.EDIT,
                params: { transactionId: transaction.id.toString() },
            });
        }

        function isCancelDisabled(transactionDigest: TransactionDigest) {
            if (transactionDigest.status === TransactionStatus.ORDERED || transactionDigest.status === TransactionStatus.ALLOCATED) {
                return !Permissions.PARTNER_ENGAGEMENT.canCancelOrders();
            }
            if (transactionDigest.getDirection(profileStore.userLocation) === 'inbound') {
                return transactionDigest.status > TransactionStatus.ALLOCATED;
            }
            return transactionDigest.status > TransactionStatus.PICKED;
        }

        function isPrintPackListDisabled(transactionDigest: TransactionDigest) {
            if (transactionDigest.getDirection(profileStore.userLocation) === 'inbound') {
                return transactionDigest.status < TransactionStatus.DELIVERY_CONFIRMED;
            }
            return transactionDigest.status < TransactionStatus.IN_TRANSIT;
        }

        async function openCancel(rowData: TransactionTableData) {
            const row = state.resultSet.find((t) => t.id === rowData.id) as TransactionDigest;

            if (!row.id) {
                notification.showError('Transaction does not exist.');
                return;
            }

            const isConfirmed = await confirm({
                title: 'Cancel Order',
                message: 'Are you sure you want to cancel this transaction?',
            });

            if (isConfirmed) {
                const response = await transactionService.cancelTransactionById(row.id);
                if (response) {
                    state.resultSet = state.resultSet.filter((t) => t.id !== rowData.id);
                }
            }
        }

        function canEditTransaction(transactionDigest: TransactionDigest): boolean {
            return Permissions.ADMINISTRATION.canEditTransactions() && transactionDigest.canEdit(profileStore.userLocation);
        }

        async function getTransactionBillOfLading(data: TransactionDigest) {
            state.loading = true;
            await transactionService.getBillOfLading(data.id);
            state.loading = false;
        }

        async function getTransactionTicket(data: TransactionDigest) {
            state.loading = true;
            await transactionService.getTransactionTicketById(data.id, data.status, data.actualDepartureDate, data.plannedDepartureDate);
            state.loading = false;
        }

        return {
            allTransactionsTable,
            inboundTransactionsTable,
            outboundTransactionsTable,
            openAddShipment,
            openAddOrder,
            openTransaction,
            isBillOfLadingDisabled,
            isCancelDisabled,
            openCancel,
            state,
            Permissions,
            canEditTransaction,
            getTitleCaseTranslation,
            getTransactionBillOfLading,
            getTransactionTicket,
            isPrintPackListDisabled
        };
    },
});
