import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07a03d21"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-card-container" }
const _hoisted_2 = { class: "advanced-filter-head" }
const _hoisted_3 = { class: "advanced-filter-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_b_button, {
        disabled: !_ctx.filterDirty,
        class: "filter-btn",
        variant: "primary",
        onClick: _ctx.submit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.titleCase(_ctx.$t('core.button.filter'))), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_b_button, {
        disabled: _ctx.filterDefault,
        class: "filter-btn",
        variant: "primary",
        onClick: _ctx.resetAllFilters
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.titleCase(_ctx.$t('core.button.reset'))), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}