
import { defineComponent, PropType } from 'vue';
import Popover from '@/components/bootstrap-library/Popover.vue';
import { formatDateTimeUI, formatDateUI } from '@/functions/date';

export default defineComponent({
    name: 'simple-date-popover',
    components: {
        Popover,
    },
    props: {
        date: {
            type: Object as PropType<Date>,
            required: true,
        },
        showTime: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return {
            formatDateTimeUI,
            formatDateUI,
        };
    },
});
