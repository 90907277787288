/* eslint-disable import/prefer-default-export */
import {
    computed, nextTick, reactive, Ref, ref, unref, UnwrapRef, onBeforeMount,
} from 'vue';
import clone from 'lodash.clone';
import { GetResultSetWithCountDTO } from '@/dtos/GetResultSetWithCountDTO';
import SearchFilterBase from '@/dtos/filters/SearchFilterBase';

type Props<T, F extends SearchFilterBase> = {
    tableKey: string;
    searchFunction: (filter: F) => Promise<GetResultSetWithCountDTO<T> | undefined>;
    defaultFilters: F;
};

type State<T> = {
    loading: boolean;
    resultSet: Array<T>;
    totalMatchedRecords: number;
};

type UseFilterSearch<T, F extends SearchFilterBase> = {
    filterState: Ref<UnwrapRef<F>>;
    submit: () => Promise<void>;
    reset: () => Promise<void>;
    state: State<T>;
    isFilterDirty: Ref<boolean>;
    filterReady: Ref<boolean>;
    isFilterDefault: Ref<boolean>;
    switchTable: (newTableKey: string) => void;
    recordCountWarning: Ref<string | undefined>;
};

export function useFilterSearch<T, F extends SearchFilterBase>(props: Props<T, F>): UseFilterSearch<T, F> {
    const tableKey = ref(props.tableKey);
    const defaultFilters = clone(props.defaultFilters);
    const filterState = ref<F>(props.defaultFilters);
    const filterReady = ref<boolean>(true);

    const state = (reactive<State<T>>({
        loading: false,
        resultSet: [],
        totalMatchedRecords: 0,
    }) as unknown) as State<T>; // https://stackoverflow.com/questions/65576268/vue-composition-api-array-in-reactive-object-typing-error
    const filterCopy = ref(clone(filterState.value));

    const isFilterDirty = computed((): boolean => JSON.stringify(filterCopy.value) !== JSON.stringify(filterState.value));

    function hydrate() {
        if (tableKey.value) {
            const data = sessionStorage.getItem(`${tableKey.value}-filterSearch`);
            if (data) {
                filterState?.value.hydrate(JSON.parse(data));
            }
        }
    }

    onBeforeMount(() => {
        hydrate();
    });

    async function submit() {
        state.loading = true;
        const dto = unref(filterState.value);
        if (tableKey.value) {
            sessionStorage.setItem(`${tableKey.value}-filterSearch`, JSON.stringify(dto));
        }

        const response = await props.searchFunction(dto as F);
        if (response) {
            state.totalMatchedRecords = response.totalMatchedRecords;
            state.resultSet = response.resultSet;
        }
        filterCopy.value = ref(clone(filterState.value)).value;
        state.loading = false;
    }

    async function resetFilterComponent() {
        filterReady.value = false;
        await nextTick(() => {
            filterReady.value = true;
        });
    }

    async function reset() {
        filterState.value = ref(clone(defaultFilters)).value;
        await resetFilterComponent();
        if (isFilterDirty.value) await submit();
    }

    const isFilterDefault = computed((): boolean => JSON.stringify(filterState.value) === JSON.stringify(defaultFilters));

    function switchTable(newTableKey: string) {
        tableKey.value = newTableKey;
        hydrate();
    }

    const recordCountWarning = computed((): string | undefined => {
        if (state.totalMatchedRecords && filterState.value.recordCount && filterState.value.recordCount < state.totalMatchedRecords) {
            return `Displaying ${filterState.value.recordCount} of ${state.totalMatchedRecords} total records. Adjust filters to narrow results`;
        }
        return undefined;
    });

    return {
        filterState,
        submit,
        state,
        reset,
        isFilterDirty,
        isFilterDefault,
        filterReady,
        switchTable,
        recordCountWarning,
    };
}
