/* eslint-disable max-classes-per-file */
import { TransactionStatus } from '@/domain/TransactionStatus';
import Location from '@/domain/Location';
import ItemSearchable from '@/dtos/filters/ItemSearchable';
import SearchFilterBase from '@/dtos/filters/SearchFilterBase';
import Item from '@/domain/Item';

export default class FilteredTransactionSearch extends SearchFilterBase implements ItemSearchable {
    constructor(init?: Partial<FilteredTransactionSearch>) {
        super(init?.recordCount);
        if (init) Object.assign(this, init);
    }

    fromDate: Date | null = null;

    toDate: Date | null = null;

    arrivedAt: Date | null = null;

    item: Item | null = null;

    locations: Location[] = [];

    statuses: Array<{ name: string; value: TransactionStatus }> = [];

    get toDTO(): FilteredTransactionSearchDTO {
        return new FilteredTransactionSearchDTO(this);
    }

    public hydrate(val: Partial<FilteredTransactionSearch>) {
        super.hydrate(val);
        this.fromDate = val.fromDate ? new Date(val.fromDate) : null;
        this.toDate = val.toDate ? new Date(val.toDate) : null;
    }
}

export class FilteredTransactionSearchDTO extends SearchFilterBase {
    constructor(init: FilteredTransactionSearch) {
        super(init.recordCount);
        this.fromDate = init.fromDate;
        this.toDate = init.toDate;
        this.locationIds = init.locations.map((l) => l.id);
        if (init.item) this.itemId = init.item.id;
        this.statuses = init.statuses.map((s) => s.value);
        this.arrivedAt = init.arrivedAt;
    }

    fromDate?: Date | null;

    toDate?: Date | null;

    itemId?: number;

    locationIds?: number[];

    statuses?: TransactionStatus[];

    arrivedAt?: Date | null;
}
